.ant-app {
  line-height: unset;
}

.ant-layout {
  background: #fff;
}

.ant-form-item {
  margin-bottom: 15px;
}

.ant-btn {
  font-size: 16px;
  padding: 5px 15px;
  height: unset;
  border-radius: 3px;
}

.ant-btn-primary {
  background: #ff7d01 !important;
  border-radius: 3px;
}

.ant-input-affix-wrapper .ant-input-clear-icon {
  font-size: 16px;
}

.ant-btn-primary:hover {
  background: #ff9b3c !important;
}

.ant-input-outlined,
.ant-input-group-wrapper-lg .ant-input-group-addon,
.ant-select-multiple.ant-select-lg .ant-select-selector {
  border-radius: 3px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #1fa7b2;
  border-color: #1fa7b2;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #1fa7b2;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #1fa7b2;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  width: 120px;
}

.ant-input,
.ant-input-lg,
.ant-input-affix-wrapper-lg {
  padding: 11px 12px;
}

.ant-form-large .ant-form-item .ant-form-item-label > label {
  height: auto;
}

.ant-btn-lg {
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-size: 20px;
  width: 100%;
}

h1.ant-typography {
  font-family: "Aleo";
  margin: 1rem 0 1rem 0;
  letter-spacing: 1px;
  font-size: 44px;
}

h2.ant-typography,
.ant-typography + h2.ant-typography {
  font-family: "Aleo";
  margin: 1rem 0 0 0;
  letter-spacing: 1px;
  font-size: 36px;
}

h4.ant-typography {
  font-family: "Arial";
  margin: 1rem 0 0.5rem 0;
  letter-spacing: 0px;
  font-size: 22px;
}

h3.ant-typography {
  font-family: "Aleo";
  margin: 1rem 0 0.5rem 0;
  letter-spacing: 1px;
}

h5.ant-typography {
  margin: 1rem 0 0.5rem 0;
}

.ant-typography {
  font-family: "Arial";
}

.ant-form-large .ant-form-item .ant-form-item-control-input {
  min-height: 20px;
}

.ant-form-item .ant-form-item-explain-error {
  font-size: 13px;
  text-align: left;
}

.ant-form-vertical .ant-form-item .ant-form-item-control,
.ant-form-horizontal .ant-form-item-control {
  margin-bottom: 0.75rem;
}

.center-radio,
.ant-form-horizontal .ant-form-item-control {
  margin-bottom: 0;
}

.center-radio,
.ant-form-item-row {
  margin-bottom: 0.5rem;
}

.m0 .ant-form-item-control {
  margin-bottom: 0 !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected {
  background-color: #173b3e;
  font-weight: 600;
  border-bottom: solid 1px #eee;
}

.ant-menu .ant-menu-item-divider {
  border-color: rgba(5, 5, 5, 0.1);
}

.ant-menu-dark.ant-menu-submenu-popup > .ant-menu {
  background-color: #173b3e;
}

.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark > .ant-menu .ant-menu-item-selected {
  background-color: #21575b;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected:hover {
  background-color: #173b3e;
}

.ant-menu-horizontal .ant-menu-item {
  padding-inline: 20px;
}

.ant-menu-dark .ant-menu-item {
  color: rgba(255, 255, 255, 0.85);
}

.dashboard .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.85);
}

.ant-btn > a:hover {
  color: #fff;
}
.ant-menu-light .ant-menu-item-selected,
.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: var(--text-color);
}

.ant-autocomplete[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #1fa7b2;
  border-color: #1fa7b2;
}

.blue-btn {
  background-color: #1fa7b2 !important;
  color: #fff !important;
  border-color: #1fa7b2 !important;
  border-radius: 3px !important;
}

.yellow-btn {
  background-color: #ffc614 !important;
  color: #000 !important;
  border-color: #ffc100 !important;
  border-radius: 3px !important;
}

.yellow-btn:hover {
  background-color: #ffd44f !important;
}

.blue-btn-lg {
  background-color: #1fa7b2 !important;
  color: #fff !important;
  border-color: #1fa7b2 !important;
  border-radius: 3px !important;
  padding: 9px;
}

.sw-blue-btn-lg {
  background-color: #1d54b5 !important;
  border-radius: 3px !important;
  padding: 9px;
}

.sw-blue-btn-lg:hover {
  background-color: #2264d7 !important;
}

.blue-btn:hover,
.blue-btn-lg:hover {
  background-color: #22b7c3 !important;
  color: #fff !important;
  border-color: #1fa7b2 !important;
}

.green-btn {
  background-color: #00a73e !important;
  color: #fff !important;
  border-color: #00a73e !important;
  border-radius: 3px !important;
}

.green-btn:hover {
  background-color: #00bb45 !important;
  color: #fff !important;
  border-color: #00bb45 !important;
}

.green-btn:disabled {
  background: #00ce4d !important;
  border-color: #00ce4d !important;
  color: #fff;
}

.ant-btn-default.ant-btn-disabled {
  background-color: #19becc !important;
  color: #fff !important;
  border-color: #2bb0ba !important;
}

.ant-input-group .ant-input-group-addon {
  border-radius: 3px 0 0 3px !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-start-end-radius: 3px;
  border-end-end-radius: 3px;
  background: #f26511;
  border: solid 1px #f26511;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected:hover {
  background-color: #2b7378;
}

.ant-typography + h1.ant-typography {
  margin-top: unset;
}

.ant-picker {
  border-radius: 3px;
  padding: 10px 12px;
  width: 100%;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 3px;
}

.ant-select-multiple.ant-select-lg.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-lg.ant-select-allow-clear .ant-select-selector {
  padding: 5px 30px 5px 10px;
}

.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
  background: #f0f8f9;
  border: 1px solid #8ccdd4;
}

.ant-select-multiple.ant-select-lg
  .ant-select-selection-overflow
  .ant-select-selection-item {
  border-radius: 3px;
}

.ant-select-multiple.ant-select-lg
  .ant-select-selection-overflow
  .ant-select-selection-item-remove {
  color: #1fa7b2;
}

.ant-select-multiple.ant-select-lg
  .ant-select-selection-overflow
  .ant-select-selection-item-content {
  margin-inline-end: 8px;
  font-size: 14px;
}

.ant-select-single.ant-select-lg {
  height: 46px;
}

.ant-select .ant-select-selection-placeholder {
  color: var(--grey-text);
}

.ant-select-single.ant-select-lg.ant-select-show-arrow
  .ant-select-selection-item {
  display: flex;
}

.ant-autocomplete {
  padding: 10px 12px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  color: rgba(0, 0, 0, 0.88);
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 2px;
}

.ant-select-selection-placeholder {
  display: flex;
}

.ant-autocomplete::placeholder {
  color: #bcbcbc;
}

.ant-input-outlined:hover {
  border-color: #1fa7b2;
}

.ant-autocomplete:hover {
  border-color: #1fa7b2;
}

.ant-autocomplete:focus-within {
  border-color: #1fa7b2;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
}

.ant-carousel .slick-dots li button {
  color: #21575b;
  background: #21575b;
  height: 14px !important;
  width: 14px !important;
  border-radius: 10px;
}

.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.35);
}

.ant-carousel .slick-dots li.slick-active,
.ant-carousel .slick-dots li.slick-active button {
  color: #f26511 !important;
  background: #f26511 !important;
}

.ant-card .ant-card-actions > li > span a:not(.ant-card-btn):hover {
  color: #f26511 !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: 0px;
}

.ant-carousel .slick-dots li {
  margin-inline: 2px;
  color: #21575b;
  background: #21575b;
  height: 14px !important;
  width: 14px !important;
  border-radius: 10px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}

.ant-collapse {
  border-radius: 8px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background: #21575b;
  color: #fff;
  border-radius: 0;
  font-weight: 600;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 2rem;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header,
.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0;
}

.ant-switch {
  background: rgba(0, 0, 0, 0.5);
}

.ant-switch.ant-switch-checked {
  background: #ff7d01;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #ff9b3c;
}

.ant-collapse .ant-collapse-content {
  border-top: unset;
}

.ant-input-search .ant-input-search-button {
  height: 46px;
  padding: 1.5rem;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-start-start-radius: 3px;
  border-end-start-radius: 3px;
  z-index: 0;
}

.ant-input-affix-wrapper {
  z-index: 0;
}

.ant-pagination .ant-pagination-item-active a {
  color: #f26511;
}

.ant-pagination .ant-pagination-disabled {
  background-color: #fff;
  border: #f9b993 solid 1px;
}

.ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
  color: #777;
  font-size: 16px;
}

.ant-pagination .ant-pagination-disabled:hover {
  background-color: #fff;
  border: #f9b993 solid 1px;
}

.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  min-width: 36px;
  height: 36px;
}

.ant-pagination
  .ant-pagination-next:not(.ant-pagination-disabled)
  .ant-pagination-item-link,
.ant-pagination
  .ant-pagination-prev:not(.ant-pagination-disabled)
  .ant-pagination-item-link {
  font-size: 16px;
  color: var(--black-text);
}

.ant-pagination .ant-pagination-next:not(.ant-pagination-disabled),
.ant-pagination .ant-pagination-next:active {
  background-color: #fff;
  border: #f26511 solid 1px;
}

.ant-pagination .ant-pagination-prev:not(.ant-pagination-disabled),
.ant-pagination .ant-pagination-prev:active {
  background-color: #fff;
  border: #f26511 solid 1px;
}

.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link {
  background-color: #fef5f0;
}

.ant-pagination .ant-pagination-item {
  display: none;
}

.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-jump-prev {
  display: none;
}

.ant-input-outlined.ant-input-disabled,
.ant-input-outlined[disabled] {
  color: rgba(0, 0, 0, 0.45);
}

.search .ant-btn .ant-btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ant-input-search-large .ant-input-affix-wrapper {
  height: 46px;
}

.ant-btn-default {
  color: #f26511;
  border-color: #f26511;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #f8ad81;
  color: #f6955b;
}

.ant-tabs .ant-tabs-tab-btn {
  color: #000;
}

.ant-tabs .ant-tabs-tab-btn:hover {
  color: #f26511;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs .ant-tabs-tab-btn {
  transition: all 0.1s;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #f26511;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #f26511;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  border-color: #f26511;
  background: #f26511;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border: solid 1px #eee;
  background: #f5f5f5;
  padding: 0.5rem 1.5rem;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 6px 6px 0 0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 4px;
}

.ant-btn-lg:disabled {
  background: #f7a16e !important;
  border-color: #f6955b;
  height: 52px;
  color: #fdfdfd;
}

.ant-btn-primary:disabled {
  background: #f7a16e !important;
  border-color: #f6955b;
  color: #fdfdfd;
}

.ant-spin .ant-spin-dot i {
  background-color: #f26511;
}

.ant-btn-variant-link {
  color: #ff7d01;
}

.ant-btn-variant-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: #f26511;
}

.ant-drawer .ant-drawer-body {
  padding: 0px;
}

.ant-card-bordered {
  border: 1px solid #e6e6e6;
}

.ant-spin-fullscreen {
  background-color: rgba(0, 0, 0, 0.65);
}

.ant-input::placeholder {
  color: var(--grey-text);
}

.ant-modal .ant-modal-title {
  font-size: 20px;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.55);
}

.ant-btn-default .ant-spin {
  color: #4b4b4b;
}

.ant-slider .ant-slider-mark {
  font-size: 12px;
}

.ant-slider.ant-slider-disabled .ant-slider-track {
  background-color: #1cb4bf !important;
}

.ant-slider.ant-slider-disabled .ant-slider-dot {
  border-color: rgba(0, 0, 0, 0.4);
}

.ant-slider .ant-slider-mark-text {
  color: rgba(0, 0, 0, 0.6);
}

.ant-slider .ant-slider-dot {
  border: 2px solid #d3d3d3;
}

.ant-slider .ant-slider-rail {
  background-color: rgba(0, 0, 0, 0.09);
}

.ant-btn-primary .ant-spin,
.green-btn .ant-spin,
.blue-btn .ant-spin {
  color: #fff;
}

.ant-menu-submenu-arrow {
  display: inline-block;
}

.ant-input-group
  .ant-input-group-addon
  .ant-select-focused
  .ant-select-selector {
  color: #21575b;
}

.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #1fa7b2;
}

.ant-layout .ant-layout-sider {
  background: var(--sider-bg);
  color: var(--text-color);
}

.dashboard .ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  margin-inline: 0px;
  width: unset;
  border-radius: 0;
  margin-block: 0px;
  padding-inline: 5px;
}

.ant-menu-dark {
  background-color: var(--sider-bg);
}

.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: #2b7378;
  border-radius: 0;
}

.dashboard .ant-menu-submenu:hover {
  background-color: #2b7378;
  border-radius: 0;
}

.dashboard .ant-menu-dark .ant-menu-item-selected {
  border-left: solid 2px #e6f4ff;
  border-radius: 0;
  color: #fff;
  background-color: #173b3e;
  font-weight: 600;
}

.dashboard .ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: var(--sider-inner-bg);
}

.dashboard .ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected) {
  border-left: none;
}

.dashboard
  .ant-menu-dark
  .ant-menu-sub.ant-menu-inline
  .ant-menu-item:not(.ant-menu-item-selected) {
  border-bottom: solid 1px var(--sider-border);
}

.ant-upload-wrapper .ant-upload-select {
  display: block;
}

.ant-menu-dark
  .ant-menu-sub.ant-menu-inline
  .ant-menu-item:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ):hover {
  background-color: #185e65;
}

.dashboard
  .ant-menu-sub.ant-menu-inline
  .ant-menu-item:not(.ant-menu-item-selected)
  .ant-menu-item:hover {
  background-color: #173b3e;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected:hover {
  background-color: #173b3e;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu-submenu {
  margin: 5px;
}

.ant-dropdown-menu-submenu-popup {
  min-width: 140px;
}

.ant-card {
  border-radius: 4px;
}

.ant-card .ant-card-cover img {
  border-radius: 4px;
}

.ant-card .ant-card-head {
  padding: 0 15px;
}

.ant-card .ant-card-head-title {
  font-size: 20px;
  color: var(--blue-text);
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #34b19e;
}

.ant-radio-wrapper .ant-radio-inner {
  border-width: 2px;
  width: 18px;
  height: 18px;
}

.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
  background-color: var(--sider-bg) !important;
  color: #fff !important;
}

.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
  background-color: var(--sider-bg) !important;
  color: #fff !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #34b19e;
  background-color: #34b19e;
}

.ant-radio-wrapper .ant-radio-checked::after {
  border: 1px solid #30a290;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #34b19e;
}

.ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border: 2px solid #34b19e;
}

.ant-checkbox + span {
  padding-inline-start: 12px;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #34b19e;
}

.ant-checkbox-group {
  width: 100%;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #8ccdd4;
  border: 2px solid #7ec7cf;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border: 2px solid #fff;
  border-top: 0;
  border-inline-start: 0;
}

.ant-modal .ant-modal-header {
  font-size: 20px;
  border-bottom: solid 1px #eee;
  padding: 0 0 0.5rem 0;
}

.ant-modal .ant-modal-footer {
  margin-top: 25px;
  border-top: solid 1px #eee;
  padding: 0.75rem 0 0 0;
}

.ant-modal .ant-modal-body {
  padding: 0.5rem 0;
}

.ant-select-single.ant-select-sm {
  font-size: 12px;
  height: 32px;
  padding: 2px 0;
}

.ant-select-single.ant-select-sm .ant-select-selector {
  font-size: 12px;
}

.ant-timeline .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 0;
}

.ant-qrcode {
  border-radius: 0;
}

.ant-btn-sm {
  font-size: 13px !important;
  padding: 2px 10px !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background: var(--table-header);
  color: #fff;
  text-align: center;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  text-align: center;
}

.ant-table-wrapper .ant-table-column-sorter,
.ant-table-wrapper .ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #fff;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 0;
}

.ant-table-wrapper .ant-table {
  border-radius: 0;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #30a290;
  color: #fff;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-inline-start: -8px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-inline-start: 0px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  inset-block-start: -6px;
  width: calc(50% - 20px);
  font-size: 20px;
  font-weight: 600;
  color: #21575b;
}

.ant-timeline .ant-timeline-item-head {
  width: 20px;
  height: 20px;
}

.ant-timeline .ant-timeline-item-tail {
  inset-inline-start: 8px;
  border-inline-start: 3px solid rgba(5, 5, 5, 0.1);
}

.ant-timeline .ant-timeline-item-head-blue {
  color: #ff7d01;
  border-color: #ff7d01;
}

.ant-timeline-item-content .ant-card-head-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-card .ant-card-actions > li {
  color: #21575b;
}

.ant-card .ant-card-actions > li > span a:not(.ant-card-btn) {
  color: #21575b;
}

.ant-card .ant-card-actions > li > span:hover {
  color: #f26511;
}

.ant-select-single .ant-select-selector {
  border-radius: 3px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 4px 11px !important;
  height: unset;
}

.ant-timeline.ant-timeline-label
  .ant-timeline-item-left
  .ant-timeline-item-content {
  inset-inline-start: calc(50% - -6px);
  width: calc(50% - 30px);
}

.ant-timeline.ant-timeline-alternate
  .ant-timeline-item-right
  .ant-timeline-item-content {
  text-align: start;
}

.ant-timeline.ant-timeline-label
  .ant-timeline-item-right
  .ant-timeline-item-label {
  inset-inline-start: calc(50% + 20px);
}

.ant-timeline.ant-timeline-label
  .ant-timeline-item-right
  .ant-timeline-item-content {
  width: calc(50% - 30px);
  text-align: start;
}

.ant-timeline-item-head .anticon .anticon-check-circle {
  font-size: 18px;
  background: #fdfdfd;
  border-radius: 48%;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  background: none;
}

.ant-timeline .ant-timeline-item-head-custom {
  inset-block-start: 2px;
  padding-block: 0px;
  inset-inline-start: 8px;
}

.ant-timeline .ant-timeline-item {
  padding-bottom: 30px;
}

.ant-select-dropdown .ant-select-item-option-content {
  overflow: auto !important;
  white-space: unset !important;
  text-overflow: unset !important;
  display: flex;
  justify-content: left;
  align-items: center;
}

.ant-picker-dropdown .ant-picker-ranges {
  margin: 5px 0;
}

.ant-picker-dropdown .ant-picker-ranges .ant-picker-now .ant-picker-now-btn {
  border: 1px solid #f26511;
  border-radius: 3px;
  padding: 6px 12px;
  line-height: 28px;
  font-weight: 600;
}

.ant-progress-success-bg,
.ant-progress-text {
  color: #00a73e !important;
}

.ant-result .ant-result-icon > .anticon {
  font-size: 54px;
}

.ant-result-info .ant-result-icon > .anticon {
  color: #1cb4bf;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #00a73e;
}

.ant-form-horizontal .ant-form-item-label {
  display: flex;
  align-items: center;
}

.ant-badge {
  color: unset;
}

.ant-badge .ant-badge-count {
  transform: translate(100%, -50%);
  box-shadow: none;
  font-weight: 600;
}

.ant-table-wrapper .ant-table .ant-table-title {
  border-radius: 3px 3px 0 0;
  font-size: 16px;
  text-align: center;
}

.ant-alert-success {
  background: #f3ffd8;
  border: 1px solid #00a73e;
  color: #00a73e;
}

.ant-alert {
  border-radius: 3px;
}

.ant-picker-calendar .ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.03);
}

.ant-picker-calendar-mini .ant-picker-content th {
  line-height: 38px;
}

.ant-picker-calendar-mini .ant-picker-panel {
  position: relative;
  z-index: 0;
}

.ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background: #2b7378;
}

.ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: #266569 solid 1px;
}

.ant-picker-calendar
  .ant-picker-cell-disabled.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ant-picker-calendar .ant-picker-cell {
  padding: 10px 0;
}

.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title
  .ant-dropdown-menu-submenu-expand-icon {
  inset-inline-end: 5px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon {
  margin-inline-end: 15px;
}

.picker-align.ant-select-single.ant-select-lg.ant-select-show-arrow
  .ant-select-selection-item {
  justify-content: start;
  align-items: center;
}

.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.5);
}

.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  padding: 0 15px;
}

.ant-segmented {
  background: #d4ecef;
  color: #47596c;
}

.ant-segmented.ant-segmented-lg,
.ant-segmented.ant-segmented-lg .ant-segmented-item {
  border-radius: 4px;
  width: 100%;
}

.ant-table-wrapper .ant-table-container {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.ant-table-wrapper .ant-table .ant-table-header {
  border-radius: 0;
}

.ant-card .ant-card-body {
  border-radius: 4px;
}

.ant-form-item .ant-form-item-label {
  overflow: visible;
}

.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  min-height: 0;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 1rem;
}

.ant-modal-confirm .ant-modal-confirm-content {
  margin-top: 5px;
}

.ant-slider-horizontal.ant-slider-with-marks {
  margin-bottom: 20px;
}

.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px #28696d;
}

.ant-slider .ant-slider-dot-active {
  border-color: #1cb4bf;
}

.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #8ccdd4;
}

.ant-slider:hover .ant-slider-track {
  background-color: #8ccdd4;
}

.ant-splitter-panel {
  flex-basis: 100% !important;
}

.ant-splitter .ant-splitter-panel {
  overflow: visible;
}

@media (max-width: 768px) {
  .ant-steps.ant-steps-vertical
    > .ant-steps-item:not(:last-child)
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    display: none;
  }

  .ant-form-item .ant-form-item-label {
    margin: 0 0 0.75rem 0;
  }

  .ant-result-title {
    font-size: 20px !important;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    float: none;
  }

  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 1rem;
  }

  .ant-collapse-large
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 12px 24px;
  }

  .custom-dropdown.ant-picker-dropdown {
    max-width: 100%;
    width: 100%;
    left: 15% !important;
    transform: none !important;
  }

  .ant-picker-panel-container .ant-picker-presets {
    min-height: 25rem !important;
  }
  @media (max-width: 800px) {
    .ant-picker-panel-container .ant-picker-presets {
      overflow: scroll !important;
      height: 400px;
    }
    .ant-picker-panel-layout {
      flex-direction: column !important;
    }
    .ant-picker-presets {
      max-width: 100% !important;
      min-height: 10rem !important;
    }
    .ant-picker-panels,
    .ant-picker-datetime-panel {
      flex-direction: column !important;
    }
  }

  .ant-card-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .ant-card-actions > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
