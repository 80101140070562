.grey-fill {
  background-color: var(--grey-fill);
}

.lh105 {
  line-height: 1.5rem;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
}

.fs16 {
  font-size: 16px !important;
}

.watermark-bg {
  background-image: url("../../../Assets/images/WhatWeDo/watermark.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
}

.problem-box {
  min-height: 515px;
}

@media (max-width: 768px) {
  .fs22 {
    font-size: 18px;
  }
}

@media (min-width: 769px) and (max-width: 1177px) {
  .problem-box {
    min-height: 580px;
  }
}
