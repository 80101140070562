.orange-card {
  background-color: #f26511;
  color: #fff;
  border-radius: 8px !important;
}

.yellow-card {
  background-color: #ffc100;
}

.blue-card {
  color: #fff;
  border-radius: 10px !important;
  border: none;
  background-color: var(--blue-bg);
}

.orange-card > .ant-card-body,
.yellow-card > .ant-card-body,
.blue-card > .ant-card-body {
  padding: 20px 15px !important;
}
